import React from "react";
import {
  FormColumn4Wide,
  Input,
  RequiredInputWithLabel,
  BestLabel,
  BestSelect,
  DatePickerInput
} from "best-common-react";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import PotentialMatchModal from "./PotentialMatchModal";
import { CommonConstants } from "../../constants/CommonConstants";
import Moment from "moment";
import ErrorWarningModal from "../common/ErrorWarningModal";

class NewProspectForm extends React.Component {
  render() {
    const { authStore, newProspectStore, lookupStore, errorWarningStore } = this.props.rootStore;
    const { prospect } = newProspectStore;
    const countries = lookupStore.getDropdownOptions(lookupStore.lookups.INT_PLAYER_COUNTRIES);
    const orgs = lookupStore.getDropdownOptions(lookupStore.lookups.ORGSHORTNOMSBNOBOC);
    const dateOfBirth = null;
    return (
      <div>
        <PotentialMatchModal show={newProspectStore.showMatchModal} />
        <ErrorWarningModal show={errorWarningStore.showModal} />
        <div className="mt-2">
          <div className="row">
            {authStore.isBOC ? (
              <FormColumn4Wide className="form-group">
                <RequiredInputWithLabel
                  name="org"
                  label={<FormattedMessage id="NewProspect.org" defaultMessage="Org" />}
                >
                  <BestSelect
                    name="org"
                    className={prospect.orgId <= 0 ? "is-invalid" : ""}
                    value={lookupStore.getDropdownItem(orgs, prospect.orgId)}
                    onChange={org => newProspectStore.setValue("orgId", org.value)}
                    options={orgs}
                  />
                </RequiredInputWithLabel>
              </FormColumn4Wide>
            ) : (
              ""
            )}
            <FormColumn4Wide className="form-group">
              <RequiredInputWithLabel
                name="countryOfResidence"
                label={<FormattedMessage id="NewProspect.countryOfResidence" defaultMessage="Country Of Residence" />}
              >
                <BestSelect
                  name="countryOfResidence"
                  className={prospect.countryOfResidenceId <= 0 ? "is-invalid" : ""}
                  value={lookupStore.getDropdownItem(countries, prospect.countryOfResidenceId)}
                  onChange={country => newProspectStore.setValue("countryOfResidenceId", country.value)}
                  options={countries}
                />
              </RequiredInputWithLabel>
            </FormColumn4Wide>
          </div>
          <div className="row">
            <FormColumn4Wide className="form-group">
              <RequiredInputWithLabel
                name="firstName"
                label={<FormattedMessage id="NewProspect.firstName" defaultMessage="First Name" />}
              >
                <Input
                  id="firstName"
                  value={prospect.firstName}
                  onChange={event => newProspectStore.setValue("firstName", event.target.value)}
                />
              </RequiredInputWithLabel>
            </FormColumn4Wide>
            <FormColumn4Wide className="form-group">
              <RequiredInputWithLabel
                name="lastName"
                label={<FormattedMessage id="NewProspect.lastName" defaultMessage="Last Name" />}
              >
                <Input
                  id="lastName"
                  value={prospect.lastName}
                  onChange={event => newProspectStore.setValue("lastName", event.target.value)}
                />
              </RequiredInputWithLabel>
            </FormColumn4Wide>
            <FormColumn4Wide className="form-group">
              <RequiredInputWithLabel
                name="dateOfBirth"
                label={<FormattedMessage id="NewProspect.dateOfBirth" defaultMessage="Birth Date" />}
              >
                <DatePickerInput
                  minDate={CommonConstants.DATES.MINIMUM_DATE}
                  value={
                    prospect.dateOfBirthAsString ? Moment(prospect.dateOfBirthAsString, "MM/DD/YYYY") : dateOfBirth
                  }
                  onChange={value => {
                    prospect.dateOfBirthAsString = value ? Moment(value).format("MM/DD/YYYY") : value;
                  }}
                />
              </RequiredInputWithLabel>
            </FormColumn4Wide>
            <FormColumn4Wide className="form-group">
              <RequiredInputWithLabel
                name="birthCountry"
                label={<FormattedMessage id="NewProspect.birthCountry" defaultMessage="Birth Country" />}
              >
                <BestSelect
                  name="birthCountry"
                  className={prospect.birthCountryId <= 0 ? "is-invalid" : ""}
                  value={lookupStore.getDropdownItem(countries, prospect.birthCountryId)}
                  onChange={country => newProspectStore.updateBirthCountryId(country.value)}
                  options={countries}
                />
              </RequiredInputWithLabel>
            </FormColumn4Wide>
            <FormColumn4Wide className="form-group">
              {prospect.birthCountryId === 1 ? (
                <RequiredInputWithLabel
                  name="birthState"
                  label={<FormattedMessage id="NewProspect.birthState" defaultMessage="Birth State" />}
                >
                  <BestSelect
                    name="birthState"
                    value={lookupStore.getDropdownItem(lookupStore.filteredDistricts, prospect.birthStateId)}
                    onChange={province => newProspectStore.updateBirthStateId(province.value)}
                    options={lookupStore.filteredDistricts}
                    isDisabled={!prospect.birthCountryId}
                  />
                </RequiredInputWithLabel>
              ) : (
                <RequiredInputWithLabel
                  name="birthProvince"
                  label={<FormattedMessage id="NewProspect.birthProvince" defaultMessage="Birth Province" />}
                >
                  {prospect.birthCountry ||
                  (prospect.birthCountryId &&
                    (lookupStore.filteredDistricts.length === 0 ||
                      lookupStore.filteredDistricts[0].label === "Not in System")) ? (
                    <Input
                      id="birthProvince"
                      value={prospect.birthProvince}
                      onChange={event => {
                        newProspectStore.setValue("birthProvince", event.target.value);
                      }}
                    />
                  ) : (
                    <BestSelect
                      name="birthProvince"
                      value={lookupStore.getDropdownItem(lookupStore.filteredDistricts, prospect.birthProvinceId)}
                      onChange={province => newProspectStore.updateBirthProvinceId(province.value)}
                      options={lookupStore.filteredDistricts}
                      isDisabled={!prospect.birthCountryId}
                    />
                  )}
                </RequiredInputWithLabel>
              )}
            </FormColumn4Wide>
            <FormColumn4Wide className="form-group">
              <RequiredInputWithLabel
                name="birthCity"
                label={<FormattedMessage id="NewProspect.birthCity" defaultMessage="Birth City" />}
              >
                {prospect.birthProvince ||
                ((prospect.birthProvinceId || prospect.birthStateId) &&
                  (lookupStore.filteredCities.length === 0 ||
                    lookupStore.filteredCities[0].label === "Not in System" ||
                    prospect.birthCountryId === 1 ||
                    prospect.birthCountryId === 2)) ? (
                  <Input
                    id="birthCity"
                    value={prospect.birthCity}
                    onChange={event => newProspectStore.setValue("birthCity", event.target.value)}
                  />
                ) : (
                  <BestSelect
                    name="birthCity"
                    value={lookupStore.getDropdownItem(lookupStore.filteredCities, prospect.birthCityId)}
                    onChange={city => {
                      newProspectStore.setValue("birthCityId", city.value);
                    }}
                    options={lookupStore.filteredCities}
                    isDisabled={!prospect.birthProvinceId && !prospect.birthStateId}
                  />
                )}
              </RequiredInputWithLabel>
            </FormColumn4Wide>
            <FormColumn4Wide className="form-group">
              {!prospect.nationalIdNumber ? (
                <>
                  <BestLabel>
                    <FormattedMessage id="NewProspect.nationalIdCountry" defaultMessage="National ID Country" />
                  </BestLabel>
                  <BestSelect
                    name="nationalIdCountry"
                    value={lookupStore.getDropdownItem(countries, prospect.countryOfIssueId)}
                    onChange={country => newProspectStore.setValue("countryOfIssueId", parseInt(country.value))}
                    options={countries}
                  />
                </>
              ) : (
                <RequiredInputWithLabel
                  id="nationalIdCountry"
                  label={<FormattedMessage id="nationalIdCountry" defaultMessage="National ID Country" />}
                >
                  <BestSelect
                    name="nationalIdCountry"
                    className={prospect.countryOfIssueId <= 0 ? "is-invalid" : ""}
                    value={lookupStore.getDropdownItem(countries, prospect.countryOfIssueId)}
                    onChange={country => newProspectStore.setValue("countryOfIssueId", parseInt(country.value))}
                    options={countries}
                  />
                </RequiredInputWithLabel>
              )}
            </FormColumn4Wide>
            <FormColumn4Wide className="form-group">
              {!prospect.countryOfIssueId || prospect.countryOfIssueId === 0 ? (
                <>
                  <BestLabel>
                    <FormattedMessage id="NewProspect.nationalIdNumber" defaultMessage="National ID Number" />
                  </BestLabel>
                  <Input
                    id="nationalIdNumber"
                    value={prospect.nationalIdNumber}
                    onChange={event => newProspectStore.setValue("nationalIdNumber", event.target.value)}
                  />
                </>
              ) : (
                <RequiredInputWithLabel
                  id="nationalIdNumber"
                  label={<FormattedMessage id="NewProspect.nationalIdNumber" defaultMessage="National ID Number" />}
                >
                  <Input
                    id="nationalIdNumber"
                    value={prospect.nationalIdNumber}
                    onChange={event => newProspectStore.setValue("nationalIdNumber", event.target.value)}
                  />
                </RequiredInputWithLabel>
              )}
            </FormColumn4Wide>
          </div>
        </div>
      </div>
    );
  }
}

NewProspectForm.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(NewProspectForm));
